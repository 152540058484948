import call from '../icons/phone.png'
import mail from '../icons/email.png'
import location from '../icons/location.png'
import emailjs from '@emailjs/browser';
import { useState, useRef } from 'react';

function ContactUs() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


        emailjs.sendForm('service_ovkwq1e', 'template_2xmv7j5', form.current, 'ua95kkyJoGrecmu7u')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };


    return (
        <section id="contactUs">
            <div>
                <div className=' flex   justify-center'>
                    <h1 className='text-center font-bold'>We are here <span className='text-white bg-main rounded-md py-1 px-2'>For You</span> !!!</h1>
                </div>

                <h1 className="text-center font-bold text-3xl my-5">Fell Free To Reach Us Any Time</h1>

                <div className="flex justify-center text-white rounded-xl gap-8 my-10 ">

                    <div className=' group flex flex-col items-center justify-center  bg-main w-96 h-48 rounded-xl overflow-visible '>
                        <img src={call} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-main rounded-full" />
                        <h1 className="text-center ">+966 53 146 0944</h1>
                    </div>

                    <div className='group flex flex-col items-center justify-center  bg-[#D9D9D9] w-96 rounded-xl overflow-visible '>
                        <img src={mail} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-[#D9D9D9] rounded-full" />
                        <h1 className="text-center text-main">info@forutrading.com</h1>
                    </div>

                    <div className='group flex flex-col items-center justify-center  bg-main w-96 rounded-xl overflow-visible '>
                        <img src={location} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-main rounded-full" />
                        <h1 className="text-center">Addis Ababa, Ethiopia</h1>
                        <h1 className="text-center">Futiyan Market Centre</h1>
                    </div>
                </div>

                <div className="grid grid-cols-2 justify-center gap-8 px-10">
                    <form ref={form} onSubmit={sendEmail} className="flex flex-col col-span-1 w-full items-center gap-4">
                        <input required className=" outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12 " placeholder="Full Name" name="from_name" />
                        <input className=" outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12 " placeholder="Email" name="message" />
                        <input className=" outline-none focus:border-main text-main border-2 pl-2 rounded-md w-full h-12 " placeholder="Phone" name="اهلاً" />
                        <textarea className=" outline-none focus:border-main text-main border-2  pl-2 pt-2 rounded-md w-full h-36 " placeholder="Message" name="message" ></textarea>
                        <button className="bg-main text-white rounded-md py-1 px-8 w-fit" type="submit" value="Send" >Send</button>
                    </form>

                    <iframe className='rounded-xl col-span-1 w-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3612499104183!2d38.7459791!3d9.030772599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85f10236aacb%3A0x5d3c0d0d5fb0155b!2sNudo%20Market%20Center!5e0!3m2!1sen!2ssa!4v1700964611731!5m2!1sen!2ssa"
                        width="500" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <hr className="my-10" />
            </div>
        </section>
    );
}

export default ContactUs