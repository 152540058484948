import teacherIcon from '../icons/teacherIcon.png'
import supporting from '../icons/supporting.png'
import prop from '../icons/prop.png'

function Services() {
    return (
        <>
            <div className='flex-col '>
                <h1 className='text-center font-bold'>What<span className='text-white bg-main rounded-md py-1 px-3'>We Can do</span>For You?</h1>
                <h1 className='text-center font-bold text-3xl my-5'>Services provide for you.</h1>
                <p className='text-center'>World-class services setting new standards in excellence, <br /> innovation, and customer satisfaction, delivering unmatched quality<br /> and value to our clients.</p>

                <div className='flex justify-center gap-12 mt-8 px-24'>

                    <div className='py-5 flex flex-col w-[32rem] rounded-xl bg-[#3A8BBA] text-white'>
                        <img className=" self-center h-24 w-24" src={teacherIcon} />
                        <h1 className=' self-center text-4xl font-bold'>Supporting</h1>
                        <p className='mt-5 self-center text-2xl text-center px-2 w-10/12 '>Providing technical support for the construction of factory warehouses.</p>
                    </div>

                    <div className='py-5 flex flex-col w-[32rem] rounded-xl bg-[#D8E9A8] text-[#1E5128]'>
                        <img className=" self-center h-24 w-24" src={supporting} />
                        <h1 className='self-center  text-4xl font-bold'>Consulting</h1>
                        <p className='mt-5 self-center text-2xl text-center px-2 w-10/12  '>Consulting for plastic companies.</p>
                    </div>
                    <div className='py-5 flex flex-col w-[32rem] rounded-xl bg-[#3A8BBA] text-white'>
                        <img className=" self-center h-24 w-24" src={prop} />
                        <h1 className='self-center  text-4xl font-bold'>Supporting</h1>
                        <p className='mt-5 self-center text-2xl text-center px-2 w-10/12  '>Providing support for emerging companies in the field of import and export.</p>
                    </div>
                </div>
            </div>
            <hr className='bg-gray-300 w-full h-0.5 my-20' />
        </>
    );
}

export default Services