import { footerSocialMediaData } from './Data'
import cr from '../images/CR.pdf'
import vat from '../images/Vat_Regestration.pdf'

function Footer() {
    return (
        <footer className='bg-main h-full  w-full'>

            <div className='flex flex-row justify-between p-10'>

                {/* الجوال والإيميل */}
                <div className='flex flex-col text-white items-center justify-center '>
                    <h1 className='text-2xl font-bold mb-2'>4U LTC Co.</h1>
                    <p className='self-start'>+251 94 431 4854</p>
                    <p>info@forutrading.com</p>
                </div>

                {/* الصفحات وحسابات التواصل */}
                <div className='flex flex-col gap-4 items-center justify-center'>
                    <div className='flex justify-center text-white gap-10'>
                        <a href='#contactUs'>Contact Us</a>
                        <a href='#Services'>Our Services</a>
                        <a href='#AboutUs'>About Us</a>
                        <a href='/'>Home</a>
                    </div>
                    <div className='flex justify-center gap-4 items-center '>
                        {footerSocialMediaData.map((item => (
                            <a href={item.link}>
                                <img className='h-12 w-12' src={item.icon}
                                />
                            </a>
                        )))}
                    </div>
                </div>

                {/* المرفقات */}
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-2xl font-bold text-white mb-2'>Attachments</h1>
                    <a download="4U CR" href={cr} className='w-full rounded-2xl py-1 px-4 bg-white text-main text-center mb-2'>
                        View CR
                    </a>

                    <a download="4U VAT" href={vat} className='w-full rounded-2xl py-1 px-4 bg-main border-2 border-white text-center text-white'>
                        View VAT registration
                    </a>

                </div>

            </div>
            <hr className='my-5 ' />
            <h1 className='text-center text-white pb-5'>All rights reserved to 4U Company © 2024</h1>
        </footer>
    );
}

export default Footer