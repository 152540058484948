// src/components/Navbar.js
import React, { useState } from 'react';
import logo from '../images/logo.png'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#3A8B6E] p-4">
      <div className='grid justify-items-center  absolute -top-1 right-0 left-0 ml-auto mr-auto bg-[#3A8B6E] h-28 w-28 rounded-full'>
        <a className='w-24 mr-4 mt-2'>
          <img className=' ' src={logo} />
        </a>

      </div>

      <div className="flex justify-end md:justify-center ">


        {/* Mobile menu button */}
        <div className="md:hidden">
          <button
            onClick={toggleNavbar}
            className="text-white focus:outline-none focus:text-white"
          >
            {isOpen ? (
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.293 6.293a1 1 0 010 1.414L7.414 19.293a1 1 0 01-1.414 0L2.293 16.707a1 1 0 010-1.414L14.586 2.293a1 1 0 011.414 0L19.293 6.293z"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm-1 5a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm1 4a1 1 0 100 2h14a1 1 0 100-2H4z"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop menu */}
        <div className={`md:flex justify-between gap-48 ${isOpen ? 'hidden' : 'block'}`}>

          <div className='flex gap-10  '>
            <Link
              to="/"
              className="hidden md:block text-white hover:text-gray-300 transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/products"
              className="hidden md:block text-white hover:text-gray-300 transition duration-300"
            >
              Products
            </Link>
            <Link
              to="/services"
              className="mr-36 hidden md:block text-white hover:text-gray-300 transition duration-300"
            >
              Services
            </Link>
          </div>

          <div className='flex gap-10 '>

            <Link
              to="/contact"
              className="hidden md:block text-white hover:text-gray-300 transition duration-300"
            >
              About Us
            </Link>
            <button className='hidden md:block bg-white text-black rounded-md px-4'>Let's Talk Now</button>
          </div>


        </div>

      </div>

      {/* Mobile dropdown menu */}
      {isOpen && (
        <div className="md:hidden">
          <ul className="text-white">
            <li>
              <Link
                to="/"
                className="block py-2 px-4 hover:bg-[#3A8B6E]"
                onClick={toggleNavbar}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                className="block py-2 px-4 hover:bg-green-700"
                onClick={toggleNavbar}
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="block py-2 px-4 hover:bg-green-700"
                onClick={toggleNavbar}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block py-2 px-4 hover:bg-green-700"
                onClick={toggleNavbar}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
