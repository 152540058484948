import hero_Img01 from '../images/hero_Img01.jpeg'
import hero_Img02 from '../images/hero_Img02.jpeg'

function Hero() {
    return (
        <>
            <div className='flex justify-between py-20 px-8'>
                <div className=''>
                    <h1 className='font-bold text-8xl'>Where <br /> <span className='text-[#3A8B6E]'>Export</span> <br /> Meets <br /> <span className='text-[#3A8B6E]'>Experties</span> </h1>
                    <h1 className='md:my-10 text-[32px]'> Providing products with high quality <br /> and competitive prices is our approach</h1>
                    <div className='flex gap-6'>
                        <button className='block w-1/2 text-[23px] bg-[#3A8B6E] text-black rounded-xl px-4'> Let's Talk Now</button>
                        <button className='block w-1/2 self-end text-[23px] bg-gray-300  text-black rounded-xl px-4'> Or Book Later!</button>
                    </div>
                </div>
                <div className=''>
                    <img className="w-[600px] h-[399px] rounded-xl" src={hero_Img01} />
                    <img className='w-[600px] h-[180px] rounded-xl mt-5' src={hero_Img02} />
                </div>
            </div>
            <hr className='bg-gray-300 w-full h-0.5 mb-20'/>
        </>
    );
}

export default Hero 