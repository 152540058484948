import { Data } from "./Data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomArrow from './CustomArrow';

function OurTeam() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
    };
    return (
        <>
            <div className=' flex   justify-center'>
                <h1 className='text-center font-bold'>Who is working <span className='text-white bg-main rounded-md py-1 px-2'>For You</span> ?</h1>
            </div>

            <h1 className="text-center font-bold text-3xl my-5">Meet Our Leadership Team</h1>

            <div className='w-11/12 mx-auto'>

                <Slider {...settings}>
                    {Data.map((d) => (
                        <div key={d.name} className="bg-white text-black rounded-xl py-20 px-2">

                            <div className='flex flex-col items-center bg-main rounded-3xl overflow-visible '>
                                <img src={d.img} alt="" className="w-40 h-40 transform -translate-y-14 border-8 border-main rounded-full" />
                                <p className="text-white text-xl font-semibold transform -translate-y-8">{d.name}</p>
                                <p className="bg-gradient-to-r from-[#08346A] to-[#BA3A8B] text-transparent transform -translate-y-8 bg-clip-text font-bold text-2xl text-center">{d.position}</p>
                                <p className="text-white text-center px-1 mb-10 transform -translate-y-4">{d.describe}</p>
                                {d.socialMedia?.map((socialItem) =>
                                    <div className="flex justify-center gap-3 mb-2">

                                        {socialItem.instaIcons &&
                                            <a href={socialItem.instaLink}>
                                                <img className="w-10 h-10" src={socialItem.instaIcons} />
                                            </a>
                                        }

                                        {socialItem.facebookIcon &&
                                            <a href={socialItem.facebookLink}>
                                                <img className="w-10 h-10" src={socialItem.facebookIcon} />
                                            </a>
                                        }
                                        {socialItem.tiktokIcon &&

                                            <a href={socialItem.tiktokLink}>
                                                <img className="w-10 h-10" src={socialItem.tiktokIcon} />
                                            </a>

                                        }

                                        <a href={socialItem.whatsappLink}>
                                            <img className="w-10 h-10" src={socialItem.whatsappIcon} />
                                        </a>

                                        {/* <h>{socialItem.instaIcon}</h> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </Slider >
            </div >
            <hr className="my-10" />
        </>
    );
}



export default OurTeam